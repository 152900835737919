<template>
  <div>
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-header">
          <h4 class="card-title">
            <span>Agendamento</span>
          </h4>
        </div>
        <div class="card-body">
          <b-row>
            <b-col md="4">
              <b-form-group label="Numero Pedido">
                <b-form-input
                    v-model="agendamento.NUNOTA"
                    v-maska="'###########'"
                    disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="CNPJ">
                <b-form-input
                    v-model="agendamento.CNPJ"
                    v-maska="maskCNPJ"
                    placeholder="00.000.000/0000-00"
                    :disabled="edicaoAdm"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Status Interno">
                <b-form-select
                    id="STATUSINT"
                    v-model="agendamento.STATUSINT"
                    :options="optionsStatusInterno"
                    :disabled="!edicaoAdm"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <br>

          <b-table
              :items="produtos"
              :fields="fieldsProdutos"
              responsive="sm"
              striped
              sticky-header
              show-empty
          >
            <template #cell(DESCRPROD)="row">
              {{ row.value }}
            </template>

            <template #cell(DESCRLOCAL)="row">
              {{ row.value }}
            </template>
          </b-table>

          <br>

          <b-row>
            <b-col mb="2">
              <b-form-group label="Peso Bruto">
                <b-form-input
                    v-model="agendamento.PESOBRUTO"
                    v-maska="maskPESOBRUTO"
                    min="1"
                    :disabled="edicaoAdm"
                />
              </b-form-group>
            </b-col>

            <b-col mb="2">
              <b-form-group label="Volume Caixas">
                <b-form-input
                    v-model="agendamento.VOLUMECXS"
                    v-maska="maskVOLUMECXS"
                    min="1"
                    :disabled="edicaoAdm"
                />
              </b-form-group>
            </b-col>

            <b-col mb="2">
              <b-form-group label="Volume Pallets">
                <b-form-input
                    v-model="agendamento.VOLUMEPALLETS"
                    v-maska="maskVOLUMEPALLETS"
                    min="1"
                    :disabled="edicaoAdm"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <br>

          <b-row>
            <b-col md="4">
              <b-form-group label="Tipo de Veiculo">
                <b-form-select
                    v-model="agendamento.CODTPVEIC"
                    :options="optionsVeiculos"
                    :disabled="edicaoAdm"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Email Transportadora">
                <b-form-input
                    v-model="agendamento.EMAILCTT"
                    placeholder="meu_email@exemplo.com"
                    type="email"
                    :disabled="edicaoAdm"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Telefone Fornecedor">
                <b-form-input
                    v-model="agendamento.TELEFONEFORN"
                    v-maska="maskTelefone"
                    :disabled="edicaoAdm"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <br>

          <b-row>
            <b-col md="3">
              <b-form-group label="Data para Entrega">
                <b-form-datepicker
                    id="DTENTR"
                    v-model="agendamento.DTENTR"
                    today-button
                    label-today-button="Selecionar Hoje"
                    reset-button
                    label-reset-button="Limpar"
                    placeholder="Selecione uma Data"
                    :required="true"
                    dropup
                    :min="dataMin()"
                    :date-disabled-fn="apenasDiasUteis"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Hora para Entrega">
                <b-form-select
                    v-model="agendamento.HORA"
                    :options="optionsHorarios"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Anexos">
                <b-form-file
                    id="anexos"
                    v-model="anexos"
                    multiple
                    accept="text/xml, application/pdf"
                    placeholder="Nenhum arquivo anexado"
                    drop-placeholder="Solte o arquivo aqui"
                    browse-text="Escolher"
                    :disabled="edicaoAdm"
                >
                  <template
                      slot="file-name"
                      slot-scope="{ names }"
                  >
                    <b-badge
                        v-for="name in names"
                        :key="name"
                        variant="dark"
                    >
                      {{ name }}
                    </b-badge>
                  </template>
                </b-form-file>
              </b-form-group>
            </b-col>

            <div v-if="edicaoAdm" class="DivBotaoLadoLado">
              <b-button class="mt-18 DivBotaoLadoLado" size="sm" @click="baixarAnexos" variant="primary">Baixar</b-button>
          </div>

          <div class="DivBotaoLadoLado" v-if="edicaoAdm == false">
            <b-button
                    class="mt-18 DivBotaoLadoLado"
                    size="sm"
                    variant="info"
                    @click="limpaAnexos()"
                >Limpar
                </b-button>
          </div>

          </b-row>

          <br>

          <div class="mt-1">
            <b-form-group label="Observação">
              <b-form-textarea
                  id="textarea"
                  v-model="agendamento.OBSERVACAO"
                  rows="3"
                  max-rows="6"
              />
            </b-form-group>
          </div>

          <div
              v-if="edicaoAdm"
              class="DivBotaoLadoLado"
          >
            <b-button
                v-b-toggle="'collapse-filtro'"
                class="mt-3"
                variant="danger"
                @click="$router.push({ name: 'agendamentoEntregaLista' })"
            >
              Retornar
            </b-button>
          </div>

          <div class="DivBotaoLadoLado">
            <b-button
                v-b-toggle="'collapse-filtro'"
                class="mt-3"
                variant="primary"
                @click="submit"
            >
              Salvar
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import 'bootstrap-icons/font/bootstrap-icons.css'
import {
  BBadge,
  BButton,
  BCol,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BRow,
  BTable,
  VBToggle,
} from 'bootstrap-vue'
import { maska } from 'maska'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import apiPortalSky from '@/services/apiPortalSky'
import Spinner from '@/components/Spinner.vue'

export default {
  components: {
    BFormGroup,
    BFormDatepicker,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BBadge,
  },
  directives: {
    'b-toggle': VBToggle,
    maska,
  },
  props: ['nunota', 'edicaoAdm'],
  data() {
    return {
      // MASCARAS
      maskCNPJ: '##.###.###/####-##',
      maskTelefone: ['+55 (##) ####-####', '+55 (##) # ####-####'],
      maskPESOBRUTO: ['##', '##,##', '###', '###,##', '#.###,##', '##.###,##', '###.###,##'],
      maskVOLUMECXS: ['###', '#.###', '##.###', '###.###'],
      maskVOLUMEPALLETS: ['###', '#.###', '##.###', '###.###'],

      // TABELAS
      fieldsProdutos: [
        {
          key: 'DESCRPROD',
          label: 'Produtos Vapza',
          sortable: true,
          tdClass: 'td100',
        },
        {
          key: 'DESCRLOCAL',
          label: 'Setor/Armazenamento',
          sortable: true,
          tdClass: 'td100',
        },
      ],
      produtos: [{
        CODPROD: null,
        AD_CODESTRAPOLO: '',
        DESCRPROD: '',
        DESCRLOCAL: '',
        CGC_CPF: null,
        DTPREVENT: null,
        PESOBRUTO: null,
        CODVOL: '',
        QTDNEG: null,
      }],

      // LISTAS
      optionsVeiculos: [
        {
          value: null,
          text: 'Selecione uma opção',
          disabled: true,
        },
      ],
      optionsHorarios: [

        {
          value: null,
          text: 'Selecione uma opção',
          disabled: true,
        },
      ],
      optionsStatusInterno: [
        {
          value: 'CONFERIR',
          text: 'CONFERIR',
          disabled: false,
        },
        {
          value: 'CONFERIDO',
          text: 'CONFERIDO',
          disabled: false,
        },
        {
          value: 'DIVERGENTE',
          text: 'DIVERGENTE',
          disabled: false,
        },
      ],
      anexos: null,

      // VARIAVEIS
      agendamento: {
        CODAGE: 0,
        STATUSINT: 'CONFERIR',
        NUNOTA: 0,
        CNPJ: null,
        DTENTR: null,
        HORA: null,
        CODLOCAL: null,
        PESOBRUTO: null,
        VOLUMECXS: 0,
        VOLUMEPALLETS: 0,
        CODTPVEIC: null,
        EMAILCTT: null,
        TELEFONEFORN: null,
        OBSERVACAO: '',
        DHCAD: new Date(),
        ANEPDF: null,
        ANEXML: null,
        NOMEANEXML: null,
        NOMEANEPDF: null,
        DTPROR: null,
        HORAPROR: null,
      },
      jaProrrogado: false,
    }
  },
  mounted() {
    if (this.nunota) {
      this.setNUNOTA(this.nunota)
      this.buscarPedido()
      this.isEdicao()
      this.buscarTipoVeiculos()
    }else{
      this.$router.push('/')
    }

    if (this.edicaoAdm) {
      const campoSTATUSINT = document.getElementById('STATUSINT')
      campoSTATUSINT.focus()
    }
  },
  methods: {

    // MÉTODOS DE SETTERS -------------------------------------------------------------------------

    setNUNOTA(newValue) {
      this.agendamento.NUNOTA = Number(newValue)
    },

    setVOLUMECXS(newValue) {
      this.agendamento.VOLUMECXS = Number(newValue.toString()
        .replace('.', '')
        .replace(',', '.'))
    },

    setVOLUMEPALLETS(newValue) {
      this.agendamento.VOLUMEPALLETS = Number(newValue.toString()
        .replace('.', '')
        .replace(',', '.'))
    },

    setCNPJ(newValue) {
      this.agendamento.CNPJ = this.formataCnpj(newValue)
    },

    setPESOBRUTO(newValue) {
      this.agendamento.PESOBRUTO = Number(newValue.toString()
        .replace('.', '')
        .replace(',', '.'))
    },

    setANEXML(newValue) {
      this.agendamento.ANEXML = newValue
    },

    setANEPDF(newValue) {
      this.agendamento.ANEPDF = newValue
    },

    setNOMEANEXML(newValue) {
      this.agendamento.NOMEANEXML = newValue
    },

    setNOMEANEPDF(newValue) {
      this.agendamento.NOMEANEPDF = newValue
    },

    // MÉTODOS DE REQUISIÇÕES ---------------------------------------------------------------------

    async submit() {
      if (!this.jaProrrogado) {
        if (this.agendamentoIsValido()) {
          this.validarDataHoraAgendamento()
            .then(response => {
              if (response.status === 200) {
                Spinner.StarLoad()
                this.montaAgendamento()
                setTimeout(() => this.inserirAgendamento(), 2000)
              }
            })
            .catch(error => {
              Spinner.EndLoad()
              console.error(error)
              AlertaMsg.FormataMsg({
                alert_type: 'error',
                message: 'Já existe um agendamento nesta mesma <b>Data e Hora</b>, por favor selecione outras opções',
              })
            })
        }
      } else {
        AlertaMsg.FormataMsg({
                alert_type: 'attention',
                message: 'Não é possível prorrogar o agendamento mais de uma vez!',
              })
      }
    },

    inserirAgendamento() {
      const URL = this.agendamento.CODAGE && this.agendamento.CODAGE !== 0 ? '/AgendamentoEntrega/Alterar' : '/AgendamentoEntrega/Inserir'

      apiPortalSky.post(URL, JSON.parse(JSON.stringify(this.agendamento)))
        .then(response => {
          Spinner.EndLoad()
          if (response.status === 200) {
            AlertaMsg.FormataMsg({
              alert_type: 'info',
              message: this.agendamento.CODAGE && this.agendamento.CODAGE !== 0 ? 'Alterado com sucesso' : 'Salvo com sucesso',
            })
          }
        })
        .catch(error => {
          Spinner.EndLoad()
          console.error(error)
          AlertaMsg.FormataMsg({
            alert_type: 'error',
            message: this.agendamento.CODAGE && this.agendamento.CODAGE !== 0 ? 'Erro ao alterar' : 'Erro ao salvar',
          })
        })
    },

    validarDataHoraAgendamento() {
      const DATA = this.agendamento.DTPROR ? this.agendamento.DTPROR : this.agendamento.DTENTR;
      const HORA = this.agendamento.HORAPROR ? this.agendamento.HORAPROR : this.agendamento.HORA;
      
      const URL = `/AgendamentoEntrega/ValidarDataHora?NUNOTA=${this.agendamento.NUNOTA}&data=${DATA}&hora=${HORA}&CODLOCAL=${this.agendamento.CODLOCAL}`

      return apiPortalSky.post(URL)
    },

    async isEdicao() {
      if (this.agendamento.NUNOTA) {
        const URL = `/AgendamentoEntrega/IsEdicao?NUNOTA=${this.agendamento.NUNOTA}`

        await apiPortalSky.post(URL)
          .then(response => {
            if (response.status === 200) {
              this.agendamento = response.data[0]
              this.converterEmArquivo()
              this.jaProrrogado = this.agendamento.DTPROR || this.agendamento.HORAPROR ? true : false;
              this.agendamento.DTENTR = (this.agendamento.DTPROR) ? this.agendamento.DTPROR: this.agendamento.DTENTR;
              this.agendamento.HORA = (this.agendamento.HORAPROR) ? this.agendamento.HORAPROR: this.agendamento.HORA.trim();

            }
          }, error => {
            console.error(error)
            AlertaMsg.FormataMsg({
              alert_type: 'error',
              message: 'Erro ao validar se este agendamento é edição',
            })
          })
      }
    },

    buscarPedido() {
      const URL = `/AgendamentoEntrega/Mostra?nunota=${this.agendamento.NUNOTA}`

      apiPortalSky.post(URL)
        .then(response => {
          if (response.status === 200) {
            this.limpaPedido()
            this.limpaProdutos()
            this.limpaAnexos()

            this.produtos = response.data

            this.buscarHorarios(this.produtos[0].CODLOCAL)
            //Armazena o Codigo do Local para Gravar junto com o Agendamento
            this.agendamento.CODLOCAL = this.produtos[0].CODLOCAL;

            this.setCNPJ(this.produtos[0].CGC_CPF)
            this.setPESOBRUTO(this.produtos[0].PESOBRUTO)
          } else {
            AlertaMsg.FormataMsg({
              alert_type: 'attention',
              message: 'O pedido ainda não pode ser agendado por alguns dos motivos abaixo:'
                  + '<ol>\n'
                  + '  <li>Status da Nota não <b>Aprovado</b></li>\n'
                  + '  <li>Tipo do Pedido não é um <b>Pedido de Compra</b></li>\n'
                  + '  <li>Pedido não está listado como <b>Pendente</b></li>\n'
                  + '</ol>',
            })

            // Encaminha usuario para outra tela quando houver erro
            this.edicaoAdm ? this.$router.push({ name: 'agendamentoLista' }) : this.$router.push({ name: 'login' })
          }
        })
        .catch(error => {
          console.error(error)
          AlertaMsg.FormataMsg({
            alert_type: 'error',
            message: 'Erro ao buscar dados do pedido',
          })
        })
    },

    buscarTipoVeiculos() {
      const URL = '/TipoVeiculo/Buscar'

      apiPortalSky.post(URL)
        .then(response => {
          if (response.status === 200) this.mapearOptionsVeiculos(response.data)
        })
        .catch(error => {
          console.error(error)
          AlertaMsg.FormataMsg({
            alert_type: 'error',
            message: 'Erro ao buscar tipos de veículos',
          })
        })
    },


    buscarHorarios(CODLOCAL) {

      const URL = `/LocalArmazenagem/BuscarHorario?CODLOCAL=${CODLOCAL}`

      apiPortalSky.post(URL)
        .then(response => {
          if (response.status === 200){

            this.mapearOptionsHorarios(response.data)
          }
        })
        .catch(error => {
          console.error(error)
          AlertaMsg.FormataMsg({
            alert_type: 'error',
            message: 'Erro ao buscar horarios',
          })
        })
    },

    baixarAnexos() {
      if (this.anexos.length === 0) {
        alert('Nenhum arquivo anexado para baixar.');
        return;
      }

      // Itera sobre cada arquivo selecionado
      this.anexos.forEach(file => {
        if(file.type == 'text/xml'){
          // Converte o conteúdo base64 para Blob
            const byteCharacters = atob(this.agendamento.ANEXML); // Decodifica a string base64
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: file.type });

            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.name);

            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);

            window.URL.revokeObjectURL(url);
        }
        if(file.type == 'application/pdf'){
          // Converte o conteúdo base64 para Blob
            const byteCharacters = atob(this.agendamento.ANEPDF); // Decodifica a string base64
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: file.type });

            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.name);

            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);

            window.URL.revokeObjectURL(url);
        }
      });
    },

    // MÉTODOS DE VALIDAÇÕES ----------------------------------------------------------------------

    agendamentoIsValido() {
      if (!this.agendamento.CNPJ) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'CNPJ obrigatório',
        })
        return false
      }

      if (!this.agendamento.PESOBRUTO || this.agendamento.PESOBRUTO < 1) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Peso Bruto incorreto',
        })
        return false
      }

      if (!this.agendamento.VOLUMECXS || this.agendamento.VOLUMECXS < 1) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Volume de Caixas incorreto',
        })
        return false
      }

      // if (!this.agendamento.VOLUMEPALLETS || this.agendamento.VOLUMEPALLETS < 1) {
      //     AlertaMsg.FormataMsg({ alert_type: 'error', message: 'Volume de Pallets incorreto' })
      //     return false
      // }

      if (!this.agendamento.CODTPVEIC) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Tipo de Veiculo obrigatório',
        })
        return false
      }

      if (!this.agendamento.EMAILCTT) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Email Transportadora obrigatório',
        })
        return false
      }

      if (!new RegExp(/^[^@]+@\w+(\.\w+)+\w$/).test(this.agendamento.EMAILCTT)) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Email não é válido',
        })
        return false
      }

      if (!this.agendamento.TELEFONEFORN) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Telefone Fornecedor obrigatório',
        })
        return false
      }

      if (!this.agendamento.DTENTR) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Data para Entrega obrigatório',
        })
        return false
      }

      if (!this.agendamento.HORA) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Hora para Entrega obrigatório',
        })
        return false
      }

      if (!this.anexos) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Anexo obrigatório',
        })
        return false
      }

      if (!this.anexos.find(file => file.type === 'text/xml')) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Obrigatório 1 anexo <b>XML</b>',
        })
        return false
      }

      return true
    },

    // MÉTODOS -----------------------------------------------------------------------------------------

    formataCnpj(newValue) {
      if (newValue) {
        const cnpj = newValue.replace(/\D/g, '')
        if (cnpj.length === 14) {
          return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')
        }
      }
      return newValue
    },

    mapearOptionsVeiculos(options) {
      for (const opt of options) {
        this.optionsVeiculos.push({
          disabled: false,
          value: opt.CODTPVEIC,
          text: opt.DESCR,
        })
      }
    },

    mapearOptionsHorarios(options) {
      for (const opt of options) {
        this.optionsHorarios.push({
          disabled: false,
          value: opt.ID,
          text: opt.DESCRICAO,
        })
      }
    },

    /**
     * Desabilita finais de semana
     * @param ymd
     * @param data
     */
    apenasDiasUteis(ymd, data) {
      const diaDaSemana = data.getDay()
      return diaDaSemana === 0 || diaDaSemana === 6
    },

    dataMin() {
      const data = new Date()
      data.setDate(data.getDate() + 3)
      return data
    },

    converterEmBase64() {
      for (const file of this.anexos) {
        new Promise(resolve => {
          const reader = new FileReader()
          reader.onload = function () {
            resolve(reader.result)
          }
          reader.readAsDataURL(file)
        }).then(base64 => {
          const base64Tratado = base64.replace('data:', '')
            .replace(/^.+,/, '')
          if (file.type === 'text/xml') {
            this.setNOMEANEXML(file.name)
            this.setANEXML(base64Tratado)
          }
          if (file.type === 'application/pdf') {
            this.setNOMEANEPDF(file.name)
            this.setANEPDF(base64Tratado)
          }
        }, error => {
          Spinner.EndLoad()
          console.error(error)
          AlertaMsg.FormataMsg({
            alert_type: 'error',
            message: 'Erro ao converter anexo',
          })
        })
      }
    },

    converterEmArquivo() {
      const container = new DataTransfer()

      if (this.agendamento.ANEXML) {
        container.items.add(new File([this.agendamento.ANEXML], this.agendamento.NOMEANEXML, {
          type: 'text/xml',
          lastModified: new Date(),
        }))
      }

      if (this.agendamento.ANEPDF && this.agendamento.ANEPDF !== '') {
        container.items.add(new File([this.agendamento.ANEPDF], this.agendamento.NOMEANEPDF, {
          type: 'application/pdf',
          lastModified: new Date(),
        }))
      }

      const anexos = document.getElementById('anexos')
      anexos.files = container.files
      anexos.dispatchEvent(new Event('change', { bubbles: true }))
    },

    limpaPedido() {
      this.agendamento = {
        CODAGE: 0,
        STATUSAGEN: 'AGENDADO',
        STATUSINT: 'CONFERIR',
        NUNOTA: this.agendamento.NUNOTA,
        CNPJ: null,
        DTENTR: null,
        HORA: null,
        PESOBRUTO: null,
        VOLUMECXS: 0,
        VOLUMEPALLETS: 0,
        CODTPVEIC: null,
        EMAILCTT: null,
        TELEFONEFORN: null,
        OBSERVACAO: '',
        DHCAD: new Date(),
        ANEPDF: null,
        ANEXML: null,
        NOMEANEXML: null,
        NOMEANEPDF: null,
        DTPROR: null,
        HORAPROR: null,
      }
    },

    limpaProdutos() {
      this.produtos = [{
        CODPROD: null,
        AD_CODESTRAPOLO: '',
        DESCRPROD: '',
        DESCRLOCAL: '',
        CGC_CPF: null,
        DTPREVENT: null,
        PESOBRUTO: null,
        CODVOL: '',
        QTDNEG: null,
      }]
    },

    limpaAnexos() {
      this.anexos = null
      this.setANEXML(null)
      this.setANEPDF(null)
      this.setNOMEANEXML(null)
      this.setNOMEANEPDF(null)
    },

    montaAgendamento() {
      this.setNUNOTA(this.agendamento.NUNOTA)
      this.setVOLUMECXS(this.agendamento.VOLUMECXS)
      this.setVOLUMEPALLETS(this.agendamento.VOLUMEPALLETS)
      this.setPESOBRUTO(this.agendamento.PESOBRUTO)
      this.converterEmBase64()
    },
  },
}
</script>

<style>
.mt-18 {
  margin-top: 1.8rem;
}
</style>
